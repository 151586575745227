@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
    --primary: #31883A
}

.bg-primary {
    background-color: var(var(--primary));
}

.text-primary {
    color: var(var(--primary));
}

* {
    padding: 0;
    margin: 0;
    outline: 0;
    box-sizing: border-box;
    font-family: Roboto, sans-serif;
}

::-webkit-scrollbar {
    width: 0.6rem;
    height: 0.6rem;
    margin-right: 10px;
}

::-webkit-scrollbar-corner {
    border: none;
    background: none;
}

::-webkit-scrollbar-thumb {
    background-color: rgb(61, 60, 66);
    border-radius: 3px;
    cursor: move;
}

::-webkit-scrollbar-thumb {
    background-color: #3D3C42;
    border-radius: 3px;
    cursor: move;
}

::-webkit-scrollbar-track {
    background-color: transparent;
    border: none;
}

body {
    /* display: flex; */
    color: #121214;
    /* background: #121214; */
    background: #ffffff;
    min-height: 100%;
    font-size: 12px;
}

.customText {
    letter-spacing: .2rem;
    font-family: "Open Sans", Helvetica, sans-serif;
    /* text-transform: uppercase; */
}

.containerRoutes {
    /* background: 'red'; */
    display: flex;
    flex: 1;
    height: 100vh;
    /* width: 100vh; */
}

.navBarDesatived {
    span {
        color: #ffffff;
        display: inline-block;
        margin: 0;
        text-transform: uppercase;
    }

    span::after {
        display: block;
        content: '';
        border-bottom: solid 2px #31883A;
        transform: scaleX(0);
        transition: transform 250ms ease-in-out;
    }

    span:hover::after {
        transform: scaleX(1);
    }

    span::after {
        transform-origin: 0% 50%;
    }

}

.navBarDesatived2 {
    span {
        color: #858585;
        display: inline-block;
        margin: 0;
        text-transform: uppercase;
    }

    span::after {
        display: block;
        content: '';
        border-bottom: solid 2px #31883A;
        transform: scaleX(0);
        transition: transform 250ms ease-in-out;
    }

    span:hover::after {
        transform: scaleX(1);
    }

    span::after {
        transform-origin: 0% 50%;
    }

}

.navBarActivated {
    span {
        color: #31883A;
        text-transform: uppercase;
    }

    border-bottom: 2px solid #31883A;
}

.swiper {
    display: block;
    width: 100%;
    height: 100%;
}

/* .swiper-slide { */
/* text-align: center;
    font-size: 18px;
    background: #fff; */

/* Center slide text vertically */
/* display: flex; */
/* justify-content: center; */
/* align-items: center; */
/* } */

/* .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
} */

/* .swiper {
    margin-left: auto;
    margin-right: auto;
} */


.content {
    display: flex;
    justify-content: center;

    h1 {
        /* font-family: "Poppins", sans-serif; */
        font-size: 2em;
        border-right: 2px solid rgba(255, 255, 255, .75);
        white-space: nowrap;
        overflow: hidden;
    }

    .typingAnimation {
        animation: blinkCursor 500ms steps(40) infinite normal, typing 4s steps(40) 1s normal both;
    }

    @keyframes typing {
        from {
            width: 0;
        }

        to {
            width: 22.2em;
        }
    }

    @keyframes blinkCursor {
        from {
            border-right: 2px solid rgba(255, 255, 255, .75);
        }

        to {
            border-right: 2px solid transparent;
        }
    }
}